export const accordionData = [
  {
    title: "Не знаю, что такое ретрит, расскажите, пожалуйста!",
    id: 1,
    content: `От английского Retreat - удаление от общества, уединение - обычно означает поездку в особую локацию вдали от шума цивилизации, для отдыха, медитаций, восстановления душевных сил, духовных и оздоровительных практик. Или саму локацию, которая предоставляет такие услуги.`,
  },
  {
    title: "Где мы будем жить? Пожары вас затронули?",
    id: 2,
    content: `Организаторы ретрита являются совладельцами бутик-отеля "Аларга", поэтому никаких неожиданностей, можно расслабиться и довериться радушному приёму хозяев! Это единственная гостиница на набережной тихого залива Чифтлик. Пожары нашу долину не потревожили, тут всё так же живописно!`,
  },
  {
    title: "Сколько дней длится ретрит?",
    id: 3,
    content: `7 дней (с 3 по 9 октября) будут идти практические занятия, поэтому день заезда у нас 2 октября - за день до начала программы, а выезд 10 октября - на следующий день после окончания ретрита.`,
  },
  {
    title: "Куда вносить депозит?",
    id: 4,
    content: `Для резерва места и оплаты депозита напишите личное сообщение в Telegram или What’s App ведущим ретрита:
    Марго Хостикян        Telegram: @margo_khostikyan
    Наталия Ларченко      Telegram: @natalia_larchenko`,
  },
  {
    title: "А что там можно делать, кроме медитаций?",
    id: 5,
    content: `❣️Питаться здоровой домашней пищей из натуральных продуктов, выращенных в Месте Силы
    ❣️Плавать в чистейшем море, загорать, отдыхать и наполняться бодростью на год вперед
    ❣️Путешествовать на лодке по красивейшим бухтам Средиземного моря
    ❣️Заряжать амулеты на здоровье, успешность, уверенность в себе и свою самореализацию
    ❣️Общаться с единомышленниками, рассказывать истории у костра и многое другое!`,
  },
  {
    title: "Будет ли йога?",
    id: 6,
    content: `У нас комплексное восстановление - энергетическая «нейро-йога», медитации на рассвете, "воздушные ванные" тёплым морским бризом, дополнительно возможно заказать сеанс массажа и остеопатии. `,
  },
  {
    title: "Брать ли свой коврик для йоги?",
    id: 7,
    content: `Нет. Зависит от уровня сентиментальной привязанности к вашему коврику - мы предоставляем все необходимые материалы, включая полотенца и коврики для йоги.`,
  },
  {
    title: "Будут ли какие-то экскурсии?",
    id: 8,
    content: `Кроме дневной прогулки на яхте (включено в цену), можно организовать по окончанию программы ретрита несколько интересных поездок - рядом дикие водопады, древние раскопки, живописные деревни, двухтысячелетние деревья, туристический Мармарис, Ликийские тропы... Но и бухта Чифтлик интересна сама по себе, у подножья горы, в живописной лесной долине, рядом с пещерами и "секретными" пляжами. `,
  },
  {
    title: "Если я не умею медитировать, мне имеет смысл приехать?",
    id: 9,
    content: `Да! Заодно научитесь правильно медитировать и расслабляться, под чутким руководством мастеров.`,
  },
  {
    title: "Сколько будет человек?",
    id: 10,
    content: `Количество мест ограничено самим отелем - двадцать номеров для участников и их семей, приехавших отдохнуть с ними. Так что никакой толпы, лишь наш узкий круг единомышленников. Стандартно: группа из 10 участников программы.`,
  },
  {
    title: "Какой приблизительный возраст у участников?",
    id: 11,
    content: `По возрасту у нас ограничений нет, особенно учитывая, что возраст души к возрасту тела никак не относится =)
    У нас в ретрите обычно участвуют и взрослые, и их дети!`,
  },
  {
    title: "Сколько денег нужно брать с собой сверх оплаты за ретрит? ",
    id: 12,
    content: `Сверх - зависит от того, сколько дополнительных трат планируете: остеопатия/массаж, мороженое детям, вино женщинам, сувениры родственникам, амулеты "на зарядку" себе. Именно наличными обычно рекомендуем минимум 200 евро на человека.`,
  },
  {
    title: "Можно ли расплачиваться на месте картой? В какой валюте брать наличные?",
    id: 13,
    content: `Да, везде принимаются карточки, так что много наличных брать необязательно, но для комфорта лучше иметь несколько сотен евро на всякий случай. Помимо турецких лир, евро и доллары местные жители меняют и принимают с удовольствием. `,
  },
  {
    title: "В программу всё включено?",
    id: 14,
    content: `Да, от аэропорта до аэропорта. Но это не классический full-all inclusive: газировки и алкогольные напитки не входят в стоимость, только чай, вода, фрукты в течение дня и 3х-разовое натуральное питание.`,
  },
  {
    title: "Будут ли какие-то дополнительные расходы?",
    id: 15,
    content: `Не намечаются, но вы можете пройти индивидуальные лечебные и трансформирующие сессии у Марго и Наталии, получить инициации в различные эзотерические дисциплины, приобрести амулеты, сделать расклад на таро и пр. (см раздел Дополнительные услуги)
    Также мы настоятельно рекомендуем пройти комплекс терапии "Юмейхо" у нашего остеопата за дополнительные 50€ или общий массаж за 30€.`,
  },
  {
    title: "Можно ли купить продукты питания и гигиены, и есть ли в этом необходимость?",
    id: 16,
    content: `Можно, в шаговой доступности несколько ресторанов и магазинов, в номерах есть холодильники. Но необходимости в этом нет, питание включено, как и шампуни и базовые средства гигиены. `,
  },
  {
    title: "Можно ли будет пройти индивидуальную сессию во время основной программы ретрита?",
    id: 17,
    content: `Можно, и в Месте Силы у нас всегда индивидуальная работа проходит ощутимо эффективнее, чем онлайн.`,
  },
  {
    title: "Можно ли получить у вас инициации вживую по дисциплинам Высшей Эзотерики во время ретрита?",
    id: 18,
    content: `Можно, по этому поводу обратитесь лично к ведущим ретрита:
    Марго Хостикян        Telegram: @margo_khostikyan
    Наталия Ларченко    Telegram: @natalia_larchenko`,
  },
  {
    title: "Можно ли будет приобрести амулеты, чтобы зарядить их на Месте Силы?",
    id: 19,
    content: `Да! Пишите Марго заранее для создания индивидуальных амулетов под ваш заказ.
    +79037779278
    Telegram: @margo_khostikyan`,
  },
  {
    title: "Можно ли приехать с маленьким ребёнком?",
    id: 20,
    content: `Да. Отель "Аларга" - семейный, тут есть где и с чем поиграть детям, пологий песчаный вход в море, игрушки и детская площадка, интернет. Предоставляются детские кроватки. Территория закрытая, тихая, с полным видеонаблюдением. `,
  },
  {
    title: "Хочу подарить участие в ретрите маме, так можно?",
    id: 21,
    content: `Дарите - на здоровье!`,
  },
  {
    title: "Я веган (имею какие-то ограничения в питании), какое будет питание?",
    id: 22,
    content: `Мы составляем меню с учетом пищевых особенностей (если вы придерживаетесь определенной системе питания) и ограничений участников (непереносимость/аллергия на определенные продукты). Сообщите об этом лично ведущим ретрита Наталии и Марго.`,
  },
  {
    title: "Что нужно брать с собой?",
    id: 23,
    content: `Удобную (спортивную) одежду для йоги и не натирающую обувь для прогулок в горы. В остальном - ваш стандартный багаж в отпуск на юг, маски-трубки для ныряния, солнцезащитный крем, шляпки, шлёпки, итд. Хотя всё можно докупить и тут.`,
  },
  {
    title: 'Какая погода в октябре?',
    id: 24,
    content: `Бархатный сезон! Возможен небольшой дождик, но маловероятно. Днём и вечером тепло, приятно загорать, плавать, медитировать. На вечер достаточно лёгкой одежды с длинным рукавом.`
  },
  {
    title: 'Что там с интернетом?',
    id: 25,
    content: `Всё хорошо. На всём побережье Турции интернет хуже, чем привычная вам "выделёнка", но у нас несколько разных источников совмещаются, поэтому скорость хорошая!`
  },
  {
    title: 'Не могу в этот раз приехать, можно ли пройти программу онлайн?',
    id: 26,
    content: `Возможно. Если наберётся значимое число желающих присоединиться онлайн, мы это организуем - но вебкамера волшебство лунной дорожки над морем передать не сможет.`
  },
  {
    title: 'Беспокоюсь насчет ковид - как с этим в отеле?',
    id: 27,
    content: `Безопасно. У всех номеров вход с улицы, никаких общих воздуховодов, лифтов и непроветриваемых помещений! Ресторан на свежем воздухе, весь персонал привит и соблюдаются строгие турецкие анти-ковид меры безопасности и дезинфекции.`
  },
  {
    title: 'Какие документы нужны для въезда в Турцию?',
    id: 28,
    content: `Паспорт, пцр-тест, ковид-страховка, онлайн-форма с читаемым на телефоне/бумаге QR-кодом ("Хес-Код"). Русскоговорящим странам визы точно не нужны.`
  },
  {
    title: 'Подойдёт ли ПЦР-тест или обязательно вакцинироваться от ковид для въезда в Турцию?',
    id: 29,
    content: `Подойдёт, актуально на сентябрь - но проверяйте обновлённые данные ближе к вылету, на всякий случай.`
  },
  {
    title: 'Будет ли трансфер? Как мы доберёмся до гостиницы?',
    id: 30,
    content: `Да, встретим с табличкой и улыбками прямо в аэропорту и привезём комфортным мини-автобусом прямо в отель.`
  },
  {
    title: 'Надо ли покупать сим-карту в аэропорту? Что там со связью?',
    id: 31,
    content: `Необязательно. Ваши телефоны будут прекрасно работать (заранее отключите "роуминг данных" чтобы интернет случайно не съел лишние деньги), и вы всё время будете с в пределах WiFi.`
  },
];
