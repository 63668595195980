import React from "react";
import "./About.css";

import Margo from "../../assets/images/masters/Margo.jpeg";
import Natasha from "../../assets/images/masters/Natasha.jpg";

const About = () => {
  return (
    <>
      <div className="about" id="leaders">
        <h2>О МАСТЕРАХ</h2>
      </div>
      <div className="about_block_wrapper">
        <div className="about_block">
          <img className="lazy" src={Margo} data-src={Margo} alt="master img" />
          <h4>
            <span className="about_symbol">✦</span> Марго Хостикян{" "}
            <span className="about_symbol">✦</span>
          </h4>
          <p>
            Профессиональный коуч и энерготерапевт с практическим опытом более
            10 лет, проводит ретриты и семинары на 🇷🇺 и 🇬🇧 языках по всему миру,
            основатель ретрит-центра в мощнейшем Месте Силы на планете.
            Регрессионный терапевт, духовный наставник, сертифицированный
            Учитель и Мастер практик Высшей Эзотерики: <br />
            <br />
            ✦ Тибетские жреческие практики
            <br />
            ✦ Кельтская магия и Таро
            <br />
            ✦ Рейки Фам-Рей
            <br />
            ✦ Спиритуальные искусства
            <br />
            ✦ Высшая Алхимия и многие другие.
            <br />
            <br />
            ✔ Более 3000 часов индивидуальных консультаций и групповых практик
            <br />
            ✔ Более 1000 человек трансформировали свое сознание и перешли на
            НОВЫЙ УРОВЕНЬ своей жизни
            <br />
            <br />
            ✦ Разблокировала память прошлых жизней к 30 годам
            <br />
            ✦ Прошла более 1000 км пешком по паломническому пути Camino de
            Santiago
            <br />
            ✦ Достигла сатори в 30-дневной медитации полного молчания в
            самоизоляции от мира.
            <br />
            ✦ Магистр международной экономики, 2 высших образования, полиглот.
            <br />✦ Рискнула последовать зову сердца, оставив комфортную жизнь в
            Москве, и переехала на берег Средиземного моря, посвятив себя своему
            Предназначению.
          </p>
        </div>
        <div className="about_line"></div>
        <div className="about_block">
          <img className="lazy" src={Natasha} data-src={Natasha} alt="master img" />
          <h4>
            <span className="about_symbol">✦</span> Наталия Ларченко{" "}
            <span className="about_symbol">✦</span>
          </h4>
          <p>
            Врач, гомеопат, диагност, энерготерапевт с практическим опытом 20
            лет.
            <br /> Автор уникальной методики работы с телом и проекта
            "Эзотерическая Медицина". <br />
            <br />
            ✦ В 2001 году окончила медицинский университет в Киеве.
            <br />
            ✦ В 2007 году прошла специализацию врач народной и нетрадиционной
            медицины, врач гомеопат.
            <br />
            ✦ С 2008 по 2012 год работала в клинике семейной медицины, проводила
            функциональную диагностику организма и лечение методом классической
            гомеопатии.
            <br />
            ✦ Открыв частную практику с 2012 года проводит индивидуальные
            консультации, диагностику и лечение методом гомеопатии и
            эзотерической медицины.
            <br />
            <br />
            ✦ Прошла обучение Высшей Эзотерики в международной эзотерической
            Академии. Сертифицированный Учитель энергетических дисциплин.
            <br />
            <br />✦ Основатель проекта "Эзотерическая медицина", который
            объединяет знания медицины и эзотерики. Цель: дать истинные знания
            медицины и эзотерики, убрать все иллюзии, мифы, предрассудки,
            мистику, фантазии и страхи относительно этих направлений. Все
            глубокие знания просты и практичны, ими может овладеть любой
            человек, если у него есть желание.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
